<template>
  <div class="mt-5">
    <div class="d-flex justify-content-center">
      <h3>Вход</h3>
    </div>
    <b-form @submit.prevent="onSubmit" method="post" class="d-block mx-auto col-8 col-md-7 col-lg-6 col-xl-5">
      <b-form-input v-model="login" placeholder="Логин" class="mt-2" required="required"/>
      <b-form-input v-model="password" placeholder="Пароль" class="mt-2" type="password" required="required"/>
      <b-alert class="mt-2" variant="danger" :show="errorMessage != null" dismissible @dismissed="errorMessage=null">
        {{ errorMessage }}
      </b-alert>
      <b-button type="submit" class="mt-2 mx-auto form-control">Вход</b-button>
    </b-form>
    <b-modal v-model="needChangePassword" hide-footer hide-header no-close-on-backdrop>
      <password-change :old-password="password" @changed="changePassword"/>
    </b-modal>
  </div>
</template>

<script>
import PasswordChange from "@/components/PasswordChange";

export default {
  name: 'login',
  components: {
    PasswordChange
  },
  mounted() {
    document.title = this.$route.meta.title;
  },
  data() {
    return {
      login: '',
      password: '',
      errorMessage: null,
      needChangePassword: false
    }
  },
  methods: {
    onSubmit() {
      this.errorMessage = null;
      this.$store.dispatch("login",
          {
            login: this.login,
            password: this.password
          })
          .then(response => {
            if (response.data?.changePassword === true) {
              this.needChangePassword = true;
            } else {
              this.$router.push('/properties');
            }
          })
          .catch(err => {
            if (err.response != null) {
              if (err.response.status === 403) {
                this.errorMessage = 'Введён неверный логин или пароль';
              } else {
                this.errorMessage = `Произошла ошибка при авторизации (код ${err.response.status})`;
              }
            } else {
              this.errorMessage = 'Произошла ошибка при авторизации';
            }
          });
      return false;
    },
    changePassword(newPassword) {
      this.$store.dispatch("changePassword",
          {
            login: this.login,
            password: this.password,
            newPassword
          })
          .then(() => {
            this.$store.dispatch("login",
                {
                  login: this.login,
                  password: newPassword
                })
                .then(() => {
                  this.$router.push('/users');
                })
                .catch(() => {
                  this.$bvToast.toast('Не удалось авторизоваться после смены пароля', {
                    title: 'Ошибка',
                    autoHideDelay: 5000,
                    appendToast: true
                  });
                })
                .finally(() => {
                  this.needChangePassword = false;
                });
          })
          .catch(() => {
            this.$bvToast.toast('Не удалось сменить пароль', {
              title: 'Ошибка',
              autoHideDelay: 5000,
              appendToast: true
            });
          });
    }
  }
}
</script>
