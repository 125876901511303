import axios from 'axios';
import store from '@/store';
import router from "@/router";

const AUTH_AXIOS = axios.create({
    baseURL: '/api/auth',
    timeout: 300000
});

const AXIOS = axios.create({
    baseURL: '/api/settings',
    timeout: 300000
});

AXIOS.interceptors.response.use(response => response, error => {
    const status = error.response ? error.response.status : null;
    if (status === 401) {
        store.commit("setAuthorized", false);
        store.commit("setRoles", []);
        return store.dispatch("refreshToken")
            .then(_ => {
                error.config.baseURL = undefined;
                return AXIOS.request(error.config);
            })
            .catch(err => {
                if (err.response?.status === 401) {
                    router.push('/login');
                }
            });
    }
    return Promise.reject(error);
});

export {AXIOS, AUTH_AXIOS};
