import Vue from 'vue';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import App from './components/App';
import router from './router';
import {BootstrapVue, BootstrapVueIcons} from 'bootstrap-vue';
import Vuelidate from 'vuelidate';
import Clipboard from 'v-clipboard'
import store from './store.js';

Vue.config.productionTip = false;

// Bootstrap
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(Vuelidate);
Vue.use(Clipboard);

new Vue({
    el: '#app',
    router,
    store,
    render: h => h(App)
});
