import Vue from 'vue';
import Router from 'vue-router';
import Login from '@/views/Login';
import Property from '@/views/server-params/Property'
import PropertyList from '@/views/server-params/PropertyList';
import store from "@/store";

Vue.use(Router);

const router = new Router({
    mode: 'history',
    base: '/',
    routes: [
        {path: '/', redirect: '/properties'},
        {path: '/login', component: Login, meta: {title: 'Вход'}},
        {path: '/properties', component: PropertyList, meta: {title: 'Серверные параметры'}},
        {path: '/property/create', component: Property, meta: {title: 'Создание параметра'}, props: {creating: true}},
        {path: '/properties/:id', component: Property, meta: {title: 'Параметр'}, props: {creating: false}}
    ]
});

router.beforeEach(async (to, from, next) => {
    if (store.getters.isAuthorized == null) {
        await store.dispatch("refreshToken")
            .catch(() => {});
    }

    const directingPath = to.path.endsWith('/') ? to.path.slice(0, -1) : to.path;

    if (!store.getters.isAuthorized && '/login' !== directingPath) {
        return next('/login');
    }
    if (store.getters.isAuthorized && '/login' === directingPath) {
        return next('/properties');
    }
    if (store.getters.isAuthorized && store.getters.isRolesEmpty && '/properties' !== directingPath) {
        return next('/properties');
    }
    if (!store.getters.isAdmin && '/property/create' === directingPath) {
        return next(from.path);
    }
    return next();
});

export default router;
