<template>
  <div>
    <b-navbar v toggleable="lg" variant="primary">
      <b-navbar-nav>
        <template v-if="!isAuthorized">
          <b-nav-item v-bind:to="'/login'">Вход</b-nav-item>
        </template>
        <template v-else>
          <b-nav-item v-if="!isRolesEmpty" v-bind:to="'/properties'">Серверные параметры</b-nav-item>
          <b-nav-item @click="logoutClicked">Выход</b-nav-item>
        </template>
      </b-navbar-nav>
    </b-navbar>
    <b-container class="pt-2">
      <router-view></router-view>
    </b-container>
  </div>
</template>
<script>
import {mapGetters} from "vuex";

export default {
  name: "app",
  mounted() {
    document.title = "Настройки";
  },
  computed: {
    ...mapGetters([
      "isAuthorized",
      "isRolesEmpty"
    ])
  },
  methods: {
    logoutClicked() {
      this.$store.dispatch("logout")
          .then(() => {
            this.$router.push("/login");
          })
          .catch(err => {
            if (err.response?.status === 401) {
              this.$router.push("/login");
            }
          });
    }
  }
};
</script>
<style scoped>
.nav-item.nav-item.nav-item a {
  color: white;
}
</style>
